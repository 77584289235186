@use 'sass:math';

$out-of-range-color: lighten(#424242, 40%);
$out-of-range-bg-color: lighten(#424242, 70%);

$calendar-border: #fafafa;
$cell-border: #fafafa;

$border-color: #f5f5f5;

$segment-width: percentage(math.div(1, 7));

$time-selection-color: white;
$time-selection-bg-color: rgba(0, 0, 0, 0.5);
$date-selection-bg-color: rgba(0, 0, 0, 0.1);

$event-bg: #12aae4;
$event-border: darken(#1769a5, 10%);
$event-outline: #1769a5;
$event-color: #fff;
$event-border-radius: 4px;
$event-padding: 3px 8px;
$event-zindex: 4;

$btn-color: #424242;
$btn-bg: #fff;
$btn-border: #f5f5f5;

$current-time-color: #74ad31;

$rbc-css-prefix: rbc-i;

$today-highlight-bg: #f5f5f5;
